import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Head from 'components/head';
import Hero from 'components/hero-background';
import Title from 'components/title';
import TitleCss from 'components/title/title.css';
import Subtitle from 'components/text/subtitle/subtitle.css';
import styled from 'styled-components';
import { colors } from 'constants/theme';
import { graphql } from 'gatsby';
import MEDIA from 'helpers/mediaTemplates';

const ContactDetails = styled(Subtitle)`
  margin-top: 2rem;
  align-self: flex-end;
  ${MEDIA.DESKTOP`
    align-self: flex-start;
  `};
`;

const StyledTitle = styled(TitleCss)`
  margin-bottom: 2rem;
  align-self: flex-start;
`;

const Index = ({ data }) => (
  <Layout>
    <Hero background={data.homeJson.hero.backgroundImage}>
      <StyledTitle as="h1" size="medium" color={colors.monochrome05}>
        MICHAEL CHENG
      </StyledTitle>
      <Title as="h2" size="large" color={'#fff'}>
        {data.homeJson.hero.text.childMarkdownRemark.rawMarkdownBody}
      </Title>
      <ContactDetails as={'p'} color={colors.monochrome05}>
        Contact me at{' '}
        <a
          href={'mailto:michael@michael.ly'}
          target="_blank"
          rel="noopener noreferrer"
        >
          michael@michael.ly
        </a>
      </ContactDetails>
    </Hero>
  </Layout>
);

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      hero {
        text {
          childMarkdownRemark {
            html
            rawMarkdownBody
          }
        }
        backgroundImage {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
