import styled from 'styled-components';
import { colors } from 'constants/theme';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.header`
  display: block;
  position: fixed;
  width: 100%;
  padding: 1.5rem 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 3;
`;

export const InnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4rem;

  a {
    color: rgba(255, 255, 255, 1);
    transition: color 0.2s ease;
    text-decoration: none;

    &:hover {
      color: ${colors.accent};
    }
  }

  ${MEDIA.TABLET`
    padding: 0 2rem;
  `};
`;
