import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: ${({ bc }) => bc};
  opacity: 0.8;
`;

export const OuterContainer = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100vh;
  min-width: 100vw;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  max-width: 60vw;
  z-index: 2;

  min-height: 60vh;
  height: auto;
  padding: 8rem 2rem;

  ${MEDIA.DESKTOP`
    max-width: 80vw;
  `};

  ${MEDIA.TABLET`
    max-width: 100vw;
    padding: 8rem 2rem;
  `};
`;
