import styled from 'styled-components';

export default styled.p`
  display: block;

  font-size: ${({ size }) => () => {
    switch (size) {
      case 'small':
        return '1.6rem';
      case 'medium':
        return '2rem';
      case 'large':
        return '2.6rem';
      default:
        return '2rem';
    }
  }};
  font-weight: 300;
  line-height: 1.2;
  color: ${props => props.color || 'inherit'};
`;
