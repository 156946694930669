export const colors = {
  accent: '#76B9EB',
  brand: '#76B9EB',
  accent01: '#00a1c9',

  monochrome01: '#16191f',
  monochrome02: '#545b64',
  monochrome03: '#879196',
  monochrome04: '#aab768',
  monochrome05: '#d5dbdb',
  monochrome06: '#eaeded',
  monochrome07: '#fafafa',
  monochrome08: '#ffffff',

  copper: '#eb5f07',
  manganese: '#ec7211',
  sulphur: '#f79f25',
  argon: '#00a1c9',
  phosphorus: '#3c8700',
  neon: '#df3312',
};
