import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { InnerContainer, OuterContainer, Overlay } from './hero.css';
import BackgroundImage from 'gatsby-background-image';

export default class BackgroundSection extends PureComponent {
  render() {
    const { background, children, overlayColor } = this.props;

    return (
      <BackgroundImage Tag="div" fluid={background.childImageSharp.fluid}>
        <OuterContainer>
          <InnerContainer>{children}</InnerContainer>
        </OuterContainer>
        <Overlay bc={overlayColor} />
      </BackgroundImage>
    );
  }
}

BackgroundSection.propTypes = {
  children: PropTypes.node.isRequired,
  background: PropTypes.string.isRequired,
  overlayColor: PropTypes.string.isRequired,
};

BackgroundSection.defaultProps = {
  background: null,
  overlayColor: '#000',
  children: null,
};
