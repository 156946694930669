import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.nav`
  ul {
    display: flex;
    list-style: none;
    padding: 0;

    li {
      text-transform: capitalize;
      font-size: 1.6rem;

      ${MEDIA.TABLET`
        font-size: 1.3rem;
      `};

      & + li {
        margin-left: 3rem;

        ${MEDIA.TABLET`
          margin-left: 2rem;
        `};
      }
    }
  }
`;
